<template>
    <div>
        <div class="block"></div>
        <div class="container container-tight ts-align-center">
            <h1>404</h1>

            <p>
                <strong>Page not found :(</strong>
            </p>
            <p>The requested page could not be found.</p>
        </div>
    </div>
</template>
<script>
export default {
    meta() {
        return this.$generateMeta({
            title: this.$i18n.t("meta.not_found.title"),
            comment: this.$i18n.t("meta.not_found.title"),
        })
    },
}
</script>
